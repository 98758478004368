import { Label, Icon, Drawer, Typography } from "@anchor/react-components";
import { useSelector } from "react-redux";
import "./Hamburger.css";
const Hamburger = ({ setIsMenuOpen, isMenuOpen, handleLogout }) => {
  const tableState = useSelector((state) => state.tableReducer);
  return (
    <Drawer
      className="drawer"
      direction="right"
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      zindex={9999}
    >
      <div className="drawer-custom">
        <div
          className="drawer-custom-close shift-right"
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log("closed");
            setIsMenuOpen(false);
          }}
        >
          <Label id="close" label="Close" />
          <Icon name="times-circle" />
        </div>

        <br />
        <br />
        <div className="drawer-custom-close">
          <Icon name="user-circle" />
          <Typography
            name
            variant="body2"
            className="name-heading"
          >
            Name
          </Typography>
        </div>
        <Typography
          variant="body2"
          className="drawer-title"
        >
          {tableState?.userInfo?.displayName? tableState.userInfo.displayName : 'User Name'}
        </Typography>

        <div className="drawer-custom-close">
          <Typography
            variant="body2"
            className="drawer-heading-title"
          >
            Job Title
          </Typography>
        </div>
        <Typography
          variant="body2"
          className="drawer-title"
        >
          {tableState?.userInfo?.jobTitle ? tableState.userInfo.jobTitle : 'Job Title Undefined'}
        </Typography>

        <div className="drawer-custom-close">
          <Typography
            variant="body2"
            className="drawer-heading-title"
          >
            E-mail
          </Typography>
        </div>
        <Typography
          variant="body2"
          className="drawer-title"
        >
          {tableState?.userInfo?.mail? tableState.userInfo.mail : 'Undefined'}
        </Typography>

        

        <div className="drawer-divider-line"/>

        <div
          className="drawer-custom-close drawer-cta-button"
          onClick={() => {
            handleLogout("redirect");
          }}
        >
          <Icon name="door-arrow-right" />
          <Typography
            variant="body2"
            className="drawer-title-icon"
          >
            Log Out
          </Typography>
        </div>
      </div>
    </Drawer>
  );
};

export default Hamburger;