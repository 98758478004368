import React, { useEffect, useState } from "react";
import {
  Button,
  TableV2,
  Typography,
  LoadingIndicator,
  Breadcrumb,
  Tooltip,
  Icon,
  Accordian,
  Switch,
} from "@anchor/react-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import "./RecPage.css";
import RecModal from "../../components/Modals/RecModal";
import { ToastFunction } from "../../ToastFuctions";
import EditRecModal from "../../components/Modals/EditRecModal";

const RecPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [select, setSelect] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const moduleState = useSelector((state) => state.moduleReducer);
  const tableState = useSelector((state) => state.tableReducer);
  const recState = useSelector((state) => state.recReducer);
  const [result, setResult] = useState("");
  const [resetSelect, setResetSelect] = useState(false);

  const switchClickHandler = (name, isActive, index) => {
    axios
      .post(`${process.env.REACT_APP_PRO_URL}/update/rec-is-active`, {
        Modulename: moduleState.moduleName,
        ruleName: tableState.ruleName,
        recName: name,
        isActive: !isActive,
        AddedBy: tableState.apmid,
      })
      .then((res) => {
        console.log(res);
      });
  };
  console.log(recState.recActiveState);
  useEffect(() => {
    const getRecommendation = async () => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_PRO_URL}/fetch/rec`, {
          Modulename: moduleState.moduleName,
          ruleName: tableState.ruleName,
        })
        .then((res) => {
          const activeValue =
            res.data &&
            res.data.map((item) => {
              return item.Isactionactive;
            });
          if (activeValue !== null)
            dispatch({ type: "REC_ACTIVE_STATE", payload: activeValue });
          if (res.data !== null) {
            dispatch({ type: "REC_TABLE", payload: res.data });
          }
          setLoading(false);
        })
        .then(() => {});
    };
    getRecommendation();
  }, [recState.recIdState]);

  const getCriteria = () => {
    setLoading1(true);
    axios
      .post(`${process.env.REACT_APP_PRO_URL}/fetch/criteria`, {
        Modulename: moduleState.moduleName,
        RuleName: tableState.ruleName,
      })
      .then((res) => {
        setResult(res.data);
        setLoading1(false);
      });
  };

  const deleteRow = () => {
    console.log(select);
    let a = "";
    const remove = select.map((item) => {
      a += item.name + ",";
      return item.name;
    });
    a = "{" + a.substring(0, a.length - 1) + "}";
    axios.post(`${process.env.REACT_APP_PRO_URL}/delete/rec`, {
      ModuleName: moduleState.moduleName,
      RuleName: tableState.ruleName,
      RecName: a,
    });
    let abcClone = tableState.recTable;
    remove.forEach((item) => {
      abcClone = abcClone.filter((obj) => obj.Name !== item);
    });
    dispatch({ type: "REC_TABLE", payload: abcClone });
  };

  return (
    <div className="main">
      {modal2 && <RecModal setModal2={setModal2} />}
      {editModal && (
        <EditRecModal
          setEditModal={setEditModal}
          select={select}
          setResetSelect={setResetSelect}
        />
      )}
      <div className="header-div">
        <Typography className="heading" variant="h4">
          Recommendations
        </Typography>
        <Tooltip
          appearance="*inverse"
          content="Recommendation Page information"
          position="top"
          width="auto"
        >
          <div
            className="info-icon"
            onClick={() => window.open("/rec-info", "_blank")}
          >
            <Icon name="info-circle" />
          </div>
        </Tooltip>
        <Breadcrumb
          activeItem="editProfile"
          className="breadcrumb-story-rec"
          mainObject={{
            editProfile: {
              breadcrumbLocator: "home.profile.editProfile",
              label: "Recommendation",
              path: "/recommendation",
            },
            home: {
              breadcrumbLocator: "home",
              label: `Module (${moduleState.moduleName})`,
              path: "/module",
            },
            profile: {
              breadcrumbLocator: "home.profile",
              label: `Rule (${tableState.ruleName})`,
              path: "/rules",
            },
          }}
          onBreadcrumbClick={(e) => {
            if (e.path == "/rules") {
              dispatch({ type: "REC_TABLE", payload: [] });
            } else if (e.path == "/module") {
              dispatch({ type: "REC_TABLE", payload: [] });
              dispatch({ type: "RULE_TABLE", payload: [] });
            }
            navigate(e.path);
          }}
          separator="/"
        />
        <div className="create-button">
          <Button
            appearance="default"
            id="primary"
            justifyItems="center"
            label="Create"
            name="primary"
            onClick={() => {
              setModal2(true);
            }}
            variant="filled"
          />
          {select.length === 0 ? (
            <>
              <div className="delete-icon" disabled>
                <Icon name="trash" />
              </div>
              <div className="edit-icon" disabled>
                <Icon name="pencil" />
              </div>
            </>
          ) : (
            <>
              {select.length > 1 ? (
                <>
                  <div
                    className="delete-icon"
                    onClick={() => {
                      deleteRow();
                      setResetSelect(true);
                      console.log("clicked");
                      ToastFunction("Deleted successfully !", "success");
                    }}
                  >
                    <Icon name="trash" />
                  </div>
                  <div className="edit-icon" disabled>
                    <Icon name="pencil" />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="delete-icon"
                    onClick={() => {
                      let isExecuted = window.confirm(
                        "Are you sure to delete this Recommendation?"
                      );
                      if (isExecuted) {
                        deleteRow();
                        setResetSelect(true);
                        ToastFunction("Deleted successfully !", "success");
                      } else {
                        setResetSelect(true);
                      }
                    }}
                  >
                    <Icon name="trash" />
                  </div>
                  <div
                    className="edit-icon"
                    onClick={() => {
                      setEditModal(true);
                    }}
                  >
                    <Icon name="pencil" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="module-table-div">
        <div className="rule-lookup">
          <Accordian
            headerContent={`Rule (${tableState.ruleName})`}
            item={{
              expanded: open,
              icon: "info-circle",
              id: "test",
              title: "Test",
            }}
            onCardToggle={() => {
              setOpen((prev) => !prev);
              getCriteria();
            }}
            variant="default"
            expanded={open}
            size="medium"
            className="accordian"
          >
            <p>
              {result == "" && !loading1 ? (
                <>
                  <Typography className="heading" variant="h6">
                    set rules from rules page
                  </Typography>
                </>
              ) : (
                <>{result}</>
              )}
              {result == "" && loading1 && (
                <LoadingIndicator
                  appearance="default"
                  fit="medium"
                  id="testing"
                  label="Loading"
                  variant="spinner"
                />
              )}
            </p>
          </Accordian>
        </div>
        <div className="rec-table">
          <TableV2
            columnData={[
              {
                accessorKey: "rid",
                header: "RID",
                id: "rid",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "name",
                header: "Name",
                id: "name",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "priority",
                alignData: "left",
                header: "Priority",
                id: "priority",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "desc",
                alignData: "left",
                header: "Description",
                id: "desc",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "assign",
                alignData: "left",
                header: "Added by",
                id: "assign",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "addedDate",
                header: "Added Date",
                id: "addedDate",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "ractive",
                header: "Active",
                id: "ractive",
                meta: {
                  type: "custom",
                },
              },
            ]}
            defaultData={tableState.recTable.map((item, index) => ({
              priority: item.Priority,
              name: item.Name,
              desc: item.Description,
              assign: item.Addedby,
              addedDate: item.Addeddate,
              rid:
                item.RID == "Loading..." ? (
                  <LoadingIndicator
                    appearance="default"
                    fit="small"
                    hiddenlabel
                    label="loading"
                    id="testing"
                    variant="spinner"
                  />
                ) : (
                  item.RID
                ),
              ractive: (
                <Switch
                  checked={recState.recActiveState[index]}
                  errorMessage="something went wrong"
                  fit="medium"
                  id="standard"
                  type="standard"
                  name="standard switch"
                  onChange={(e) => {
                    console.log(e);
                    switchClickHandler(
                      item.Name,
                      recState.recActiveState[index]
                    );
                    let arr = recState.recActiveState;
                    arr[index] = !arr[index];
                    dispatch({ type: "REC_ACTIVE_STATE", payload: arr });
                  }}
                  orientation="vertical"
                />
              ),
            }))}
            gridLine="both"
            maxHeight={350}
            stickyHeader={true}
            onRowSelection={(e) => {
              setSelect(e);
              setResetSelect(false);
            }}
            resetRowSelection={resetSelect}
            rowSelector={[
              {
                id: "customSelect",
              },
            ]}
            tableWidth="full-width"
          />
          <br />

          {tableState.recTable.length == 0 && !loading ? (
            <>
              <Typography className="heading" variant="h6">
                Insert Recommendations to view
              </Typography>
            </>
          ) : (
            <></>
          )}
          {tableState.recTable.length == 0 && loading && (
            <>
              <br />
              <LoadingIndicator
                appearance="default"
                fit="medium"
                id="testing"
                label="Loading"
                variant="spinner"
              />
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RecPage;
