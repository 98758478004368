import React, { useEffect, useState } from "react";
import {
  Button,
  TableV2,
  Typography,
  LoadingIndicator,
  Input,
  Icon,
  Tooltip,
} from "@anchor/react-components";
import { ToastFunction } from "../../../src/ToastFuctions";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ModulePage.css";
import ModuleModal from "../../components/Modals/ModuleModal";
import EditModuleModal from "../../components/Modals/EditModuleModal";

const ModulePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);
  const tableState = useSelector((state) => state.tableReducer);
  const moduleState = useSelector((state) => state.moduleReducer);
  const [searchData, setSearchData] = useState([]);
  const [select, setSelect] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [resetSelect, setResetSelect] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    let rbacToken;
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key))
        if (JSON.parse(localStorage.getItem(key)).credentialType === "IdToken")
          rbacToken = JSON.parse(localStorage.getItem(key)).secret;
    }
    const checkAdmin = async () => {
      axios
        .get(
          "https://anchor-rbac-retrieval-app.dev.maersk-digital.net/api/security/token/validate/anchor-rbac",
          {
            headers: {
              Authorization: `Bearer ${rbacToken}`,
            },
          }
        )
        .then((data) => {
          data = data.data.resource.forEach((item) => {
            if (item.resName === "admin") {
              setIsAdmin(true);
            }
          });
        });
    };
    const getModules = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_PRO_URL}/fetch/module`,
        { token: rbacToken }
      );
      const data = res.data.map((item, index) => ({
        modulename: item.modulename,
        rcount: item.rcount,
        addedby: item.addedby,
        addeddate: item.addeddate,
      }));
      if (data !== []) dispatch({ type: "TABLE_DATA", payload: res.data });
      else dispatch({ type: "TABLE_DATA", payload: ["no data"] });
      if (res.data !== null)
        dispatch({ type: "MODULE_TABLE", payload: res.data });
      setSearchData(data);
    };
    getModules();
    checkAdmin();
  }, []);

  const handleChange = (e) => {
    const data = searchData.filter((item) => {
      return e.target.value.toLowerCase() === ""
        ? item
        : item.modulename
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(e.target.value.replace(/\s/g, "").toLowerCase());
    });
    dispatch({ type: "MODULE_TABLE", payload: data });
  };

  const deleteRow = () => {
    let a = "";
    const remove = select.map((item) => {
      a += item.name.props.children + ",";
      return item.name.props.children;
    });
    a = "{" + a.substring(0, a.length - 1) + "}";
    console.log(a);
    axios.post(`${process.env.REACT_APP_PRO_URL}/delete/module`, {
      ModuleName: a,
    });
    let abcClone = tableState.moduleTable;
    remove.forEach((item) => {
      abcClone = abcClone.filter((obj) => obj.modulename !== item);
    });
    dispatch({ type: "MODULE_TABLE", payload: abcClone });
  };
  console.log(tableState.moduleTable);
  return (
    <div>
      {clicked && <ModuleModal setClicked={setClicked} />}
      {editModal && (
        <EditModuleModal
          setEditModal={setEditModal}
          select={select}
          setResetSelect={setResetSelect}
        />
      )}
      <div className="header-div">
        <div className="module-page-left-side">
          <Typography className="heading" variant="h4">
            Modules
          </Typography>
          <Tooltip
            appearance="inverse"
            content="Module Page information"
            position="top"
            width="auto"
          >
            <div
              className="info-icon"
              onClick={() => window.open("/module-info", "_blank")}
            >
              <Icon name="info-circle" />
            </div>
          </Tooltip>
        </div>

        <div className="create-button">
          <Input
            fit="medium"
            id="moduleText"
            maxLength={50}
            width={100}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Search Module"
            required
            tooltipDescription="Hello"
            type="text"
          />
          {isAdmin && (
            <>
              <Button
                className="create-module-button"
                appearance="default"
                id="primary"
                justifyItems="center"
                label="Create"
                name="primary"
                onClick={() => {
                  setClicked(true);
                }}
                variant="filled"
              />
              {select.length === 0 ? (
                <>
                  <div className="delete-icon" disabled>
                    <Icon name="trash" />
                  </div>
                  <div className="edit-icon" disabled>
                    <Icon name="pencil" />
                  </div>
                </>
              ) : (
                <>
                  {select.length > 1 ? (
                    <>
                      <div
                        className="delete-icon"
                        onClick={() => {
                          let isExecuted = window.confirm(
                            "Are you sure to delete this Module? The Rules and Recommendations cofigured within this will also get deleted"
                          );
                          if (isExecuted) {
                            deleteRow();
                            setResetSelect(true);
                            ToastFunction("Deleted successfully !", "success");
                          } else {
                            setResetSelect(true);
                          }
                        }}
                      >
                        <Icon name="trash" />
                      </div>
                      <div className="edit-icon" disabled>
                        <Icon name="pencil" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="delete-icon"
                        onClick={() => {
                          let isExecuted = window.confirm(
                            "Are you sure to delete this Module? The Rules and Recommendations cofigured within this will also get deleted"
                          );
                          if (isExecuted) {
                            deleteRow();
                            setResetSelect(true);
                            ToastFunction("Deleted successfully !", "success");
                          } else {
                            setResetSelect(true);
                          }
                        }}
                      >
                        <Icon name="trash" />
                      </div>
                      <div
                        className="edit-icon"
                        onClick={() => {
                          setEditModal(true);
                        }}
                      >
                        <Icon name="pencil" />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="module-table-div">
        <div className="module-table">
          <TableV2
            columnData={[
              {
                accessorKey: "name",
                header: "Name",
                id: "name",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "rules",
                alignData: "left",
                header: "No. of rules",
                id: "rules",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "assign",
                alignData: "left",
                header: "Added By",
                id: "assign",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "addedDate",
                alignData: "left",
                header: "Added On",
                id: "addedDate",
                meta: {
                  type: "custom",
                },
              },
            ]}
            defaultData={tableState.moduleTable.map((item, index) => ({
              name: (
                <div
                  onClick={() => {
                    dispatch({ type: "MODULE_NAME", payload: item.modulename });
                    const ruleTableValue = [];
                    dispatch({ type: "RULE_TABLE", payload: ruleTableValue });
                    navigate("/rules");
                  }}
                >
                  {item.modulename}
                </div>
              ),
              rules: item.rcount,
              assign: item.addedby,
              addedDate: item.addeddate,
            }))}
            gridLine="vertical"
            stickyHeader={true}
            maxHeight={405}
            onRowSelection={(e) => {
              setSelect(e);
              setResetSelect(false);
            }}
            resetRowSelection={resetSelect}
            rowSelector={
              isAdmin && [
                {
                  id: "customSelect",
                },
              ]
            }
          />
          <br />

          {tableState.tableData.length == 0 &&
          tableState.tableData[0] === "no data" ? (
            <LoadingIndicator
              appearance="default"
              fit="medium"
              id="testing"
              label="Loading"
              variant="spinner"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ModulePage;
