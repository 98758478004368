import {
  Button,
  Icon,
  Input,
  LoadingIndicator,
  TableV2,
  Breadcrumb,
  Typography,
} from "@anchor/react-components/dist/lib/components";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const APIDetails = () => {
  const navigate = useNavigate();
  const moduleState = useSelector((state) => state.moduleReducer);

  return (
    <div>
      <div className="header-div">
        <div className="left-side">
          <Typography className="heading" variant="h4">
            API Details
          </Typography>
          <Breadcrumb
            activeItem="editProfile"
            className="breadcrumb-story-rec"
            mainObject={{
              editProfile: {
                breadcrumbLocator: "home.profile.editProfile",
                label: "API Details",
                path: "/api-details",
              },
              home: {
                breadcrumbLocator: "home",
                label: `Module (${moduleState.moduleName})`,
                path: "/",
              },
              profile: {
                breadcrumbLocator: "home.profile",
                label: `API (John Doe)`,
                path: "/API",
              },
            }}
            onBreadcrumbClick={(e) => {
              // if (e.path == "/rules") {
              //   dispatch({ type: "REC_TABLE", payload: [] });
              // } else if (e.path == "/module") {
              //   dispatch({ type: "REC_TABLE", payload: [] });
              //   dispatch({ type: "RULE_TABLE", payload: [] });
              // }
              navigate(e.path);
            }}
            separator="/"
          />
        </div>
      </div>
      <div className="module-table-div">
        <div className="module-table">
          <TableV2
            columnData={[
              {
                accessorKey: "name",
                header: "Name",
                id: "name",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "method",
                alignData: "left",
                header: "Method",
                id: "method",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "header",
                alignData: "left",
                header: "Header",
                id: "header",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "apiEndPoint",
                alignData: "left",
                header: "API End Point",
                id: "apiEndPoint",
                meta: {
                  type: "custom",
                },
              },
            ]}
            defaultData={[
              {
                name: "John Doe",
                method: "GET",
                header: "{x-api-key: 1234}",
                apiEndPoint: "/test",
              },
              {
                name: "John Doe",
                method: "POST",
                header: "{x-api-key: test78}",
                apiEndPoint: "/test/put",
              },
            ]}
            gridLine="vertical"
            maxHeight={420}
            stickyHeader={true}
          />
        </div>
      </div>
    </div>
  );
};

export default APIDetails;
