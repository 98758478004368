import React from "react";
import { Card, Typography } from "@anchor/react-components/dist/lib/components";
import "./ModuleInfoPage.css";
//import "./Homepage.css";
import { useNavigate } from "react-router-dom";

function ModuleInfoPage() {
  const navigate = useNavigate();
  return (
    <div className="module-info">
    <Typography variant="h3">
      What is a module?
    </Typography>
    <p><Typography variant="body1">The Kitsuni application aims to be a generic product to provide recommendation solutions to all the other applications other than SalesForce. These products which are being supported by Kitsuni are what we call as modules.</Typography></p>
    <Typography variant="body1"> The modules page lists out all the modules of the products supported by the Kitsuni Application, for which the particular user has currently access to/working on.</Typography>
    <Typography variant="h3">
      How to create a module?
    </Typography>
  </div>
  );
}

export default ModuleInfoPage;
