const initialState = {
  apiHeaderValue: [{ key: "", value: "" }],
  apiHeader: [{}],
  apiEndpoint: "",
  apiName: "",
};
const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "API_HEADER":
      return {
        ...state,
        apiHeaderValue: action.payload,
      };

    case "API_HEADER_VALUE":
      return {
        ...state,
        apiHeader: action.payload,
      };

    case "API_ENDPOINT":
      return {
        ...state,
        apiEndpoint: action.payload,
      };

    case "API_NAME":
      return {
        ...state,
        apiName: action.payload,
      };

    default:
      return state;
  }
};

export default apiReducer;
