import React, { useEffect, useState } from "react";
import {
  Button,
  TableV2,
  Typography,
  Input,
  Breadcrumb,
  LoadingIndicator,
  Tooltip,
  Icon,
} from "@anchor/react-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./RulePage.css";
import RuleModal from "../../components/Modals/RuleModal";
import axios from "axios";
import { ToastFunction } from "../../ToastFuctions";
import EditRuleModal from "../../components/Modals/EditRuleModal";

const RulePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState([]);
  const [Modal1, setModal1] = useState(false);
  const tableState = useSelector((state) => state.tableReducer);
  const moduleState = useSelector((state) => state.moduleReducer);
  const [searchData, setSearchData] = useState([]);
  const [resetSelect, setResetSelect] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    const getRule = async () => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_PRO_URL}/fetch/rule`, {
          Modulename: moduleState.moduleName,
        })
        .then((res) => {
          let data = [];
          res.data != null
            ? (data = res.data.map((item, index) => ({
                rcount: item.rcount,
                RuleName: item.RuleName,
                configureddate: item.configureddate,
                configuredby: item.configuredby,
              })))
            : console.log(res);
          if (res.data != null)
            dispatch({ type: "RULE_TABLE", payload: res.data });
          setSearchData(data);
          setLoading(false);
        })
        .then(() => {});
    };
    getRule();
  }, []);

  const handleChange = (e) => {
    const data = searchData.filter((item) => {
      return e.target.value.toLowerCase() === ""
        ? item
        : item.RuleName.replace(/\s/g, "")
            .toLowerCase()
            .includes(e.target.value.replace(/\s/g, "").toLowerCase());
    });
    dispatch({ type: "RULE_TABLE", payload: data });
  };

  const deleteRow = () => {
    console.log(select);
    let a = "";
    const remove = select.map((item) => {
      a += item.name.props.children + ",";
      return item.name.props.children;
    });
    a = "{" + a.substring(0, a.length - 1) + "}";
    axios.post(`${process.env.REACT_APP_PRO_URL}/delete/rule`, {
      ModuleName: moduleState.moduleName,
      RuleName: a,
    });
    let abcClone = tableState.ruleTable;
    remove.forEach((item) => {
      abcClone = abcClone.filter((obj) => obj.RuleName !== item);
    });
    dispatch({ type: "RULE_TABLE", payload: abcClone });
  };

  return (
    <div>
      {Modal1 && <RuleModal setModal1={setModal1} />}
      {editModal && (
        <EditRuleModal
          setEditModal={setEditModal}
          select1={select}
          setResetSelect1={setResetSelect}
        />
      )}
      <div className="header-div">
        <div className="module-page-left-side">
          <Typography className="heading" variant="h4">
            Rules
          </Typography>
          <Tooltip
            appearance="inverse"
            content="Rule Page information"
            position="top"
            width="auto"
          >
            <div
              className="info-icon"
              onClick={() => window.open("/rule-info", "_blank")}
            >
              <Icon name="info-circle" />
            </div>
          </Tooltip>
          <Breadcrumb
            activeItem="profile"
            className="breadcrumb-story"
            mainObject={{
              home: {
                breadcrumbLocator: "home",
                label: `Module (${moduleState.moduleName})`,
                path: "/module",
              },
              profile: {
                breadcrumbLocator: "home.profile",
                label: "Rule",
                path: "/rules",
              },
            }}
            onBreadcrumbClick={(e) => {
              navigate(e.path);
            }}
            separator="/"
          />
        </div>
        <div className="create-button">
          <Input
            fit="medium"
            id="moduleText"
            className="rule-search"
            maxLength={50}
            width={100}
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Search Rule"
            required
            tooltipDescription="Hello"
            type="text"
          />
          <Button
            appearance="default"
            id="primary"
            justifyItems="center"
            label="Create"
            name="primary"
            onClick={() => {
              setModal1(true);
            }}
            variant="filled"
          />
          {select.length === 0 ? (
            <>
              <div className="delete-icon" disabled>
                <Icon name="trash" />
              </div>
              <div className="edit-icon" disabled>
                <Icon name="pencil" />
              </div>
            </>
          ) : (
            <>
              {select.length > 1 ? (
                <>
                  <div
                    className="delete-icon"
                    onClick={() => {
                      let isExecuted = window.confirm(
                        "Are you sure to delete this Rule? The Recommendations cofigured within this will also get deleted"
                      );
                      if (isExecuted) {
                        deleteRow();
                        setResetSelect(true);
                        ToastFunction("Deleted successfully !", "success");
                      } else {
                        setResetSelect(true);
                      }
                    }}
                  >
                    <Icon name="trash" />
                  </div>
                  <div className="edit-icon" disabled>
                    <Icon name="pencil" />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="delete-icon"
                    onClick={() => {
                      let isExecuted = window.confirm(
                        "Are you sure to delete this Rule? The Recommendations cofigured within this will also get deleted"
                      );
                      if (isExecuted) {
                        deleteRow();
                        setResetSelect(true);
                        ToastFunction("Deleted successfully !", "success");
                      } else {
                        setResetSelect(true);
                      }
                    }}
                  >
                    <Icon name="trash" />
                  </div>
                  <div
                    className="edit-icon"
                    onClick={() => {
                      setEditModal(true);
                    }}
                  >
                    <Icon name="pencil" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="module-table-div">
        <div className="module-table">
          <TableV2
            columnData={[
              {
                accessorKey: "name",
                header: "Name",
                id: "name",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "rec",
                alignData: "left",
                header: "No. of recommendation",
                id: "rec",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "configureDate",
                alignData: "left",
                header: "Configured Date",
                id: "configureDate",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "assign",
                alignData: "left",
                header: "Configured By",
                id: "assign",
                meta: {
                  type: "custom",
                },
              },
            ]}
            defaultData={tableState.ruleTable.map((item, index) => ({
              rec: item.rcount,
              name: (
                <div
                  onClick={() => {
                    dispatch({
                      type: "RULE_NAME_TABLE",
                      payload: item.RuleName,
                    });
                    navigate("/recommendation");
                  }}
                >
                  {item.RuleName}
                </div>
              ),
              configureDate: item.configureddate,
              assign: item.configuredby,
            }))}
            gridLine="vertical"
            maxHeight={420}
            stickyHeader={true}
            onRowSelection={(e) => {
              setSelect(e);
              setResetSelect(false);
            }}
            resetRowSelection={resetSelect}
            rowSelector={[
              {
                id: "customSelect",
              },
            ]}
          />
          {tableState.ruleTable.length == 0 && !loading ? (
            <>
              <br />
              <Typography className="heading" variant="h6">
                Insert Rules to view
              </Typography>
            </>
          ) : (
            <></>
          )}
          {tableState.ruleTable.length == 0 && loading && (
            <>
              <br />
              <LoadingIndicator
                appearance="default"
                fit="medium"
                id="testing"
                label="Loading"
                variant="spinner"
              />
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RulePage;
