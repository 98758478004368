import { Button, Modal, Input, Box } from "@anchor/react-components";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ModuleModal.css";
import { ToastFunction } from "../../ToastFuctions";
import { Group } from "@anchor/react-components/dist/lib/components/Radio";

const ModuleModal = ({ setClicked }) => {
  const dispatch = useDispatch();
  const [newModule, setNewModule] = useState("");
  const tableState = useSelector((state) => state.tableReducer);

  const [currentRadio, setCurrentRadio] = useState("");

  let today = new Date();
  let date =
    today.getDate() +
    "-" +
    parseInt(today.getMonth() + 1) +
    "-" +
    today.getFullYear();
  tableState.moduleTable.map((item) => {
    console.log(item);
  });
  let data1 = [];
  const ModuleSaveHandler = () => {
    const data = [
      {
        rcount: 0,
        modulename: newModule,
        addeddate: date,
        addedby: tableState.apmid,
      },
      ...tableState.moduleTable,
    ];
    data1 = data;
    axios
      .post(`${process.env.REACT_APP_PRO_URL}/insert/module`, {
        ModuleName: newModule.trim(),
        AssignId: tableState.apmid,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const checkDuplicate = () => {
    let c = 0;
    tableState.moduleTable.map((item) => {
      item.modulename.replace(/\s/g, "").toLowerCase() ==
      newModule.replace(/\s/g, "").toLowerCase()
        ? ToastFunction("Module already exists !", "error")
        : c++;
    });
    if (tableState.moduleTable.length == c) {
      ModuleSaveHandler();
      dispatch({ type: "MODULE_TABLE", payload: data1 });
      ToastFunction("Module added !", "success");
    }
  };
  return (
    <Modal
      actions={{
        primaryAction: (
          <Button
            label="Save"
            onClick={() => {
              newModule != ""
                ? checkDuplicate()
                : ToastFunction("Empty Module cannot be added !", "warning");
              newModule ? setClicked(false) : setClicked(true);
            }}
          />
        ),
        tertiaryAction: (
          <Button
            label="Cancel"
            onClick={() => {
              setClicked(false);
            }}
            variant="outlined"
          />
        ),
      }}
      className="module-modal"
      id="module-modal-id"
      heading="New Module"
      dimension="medium"
      open
      onClose={function noRefCheck() {}}
      showCloseIcon={false}
    >
      <div className="top-input">
        <Input
          className="moduleInput"
          errorMessage=""
          autocomplete="on"
          fit="medium"
          hint=""
          id="moduleName"
          label="Module Name"
          maxLength={50}
          onBlur={function noRefCheck() {}}
          onChange={(e) => {
            setNewModule(e.target.value);
          }}
          onClear={function noRefCheck() {}}
          onClick={function noRefCheck() {}}
          onFocus={function noRefCheck() {}}
          onIconClick={function noRefCheck() {}}
          placeholder="Enter New Module Name"
          required
          type="text"
          variant="default"
          value={newModule}
        />
      </div>
    </Modal>
  );
};
export default ModuleModal;
