const initialState = {
  moduleTable: [],
  ruleTable: [],
  tableData: [],
  recTable: [],
  ruleName: "",
  apmid: "",
  userInfo: [],
  apiTable: [],
};
const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MODULE_TABLE":
      return {
        ...state,
        moduleTable: action.payload,
      };

    case "API_TABLE":
      return {
        ...state,
        apiTable: action.payload,
      };

    case "RULE_TABLE":
      return {
        ...state,
        ruleTable: action.payload,
      };
    case "TABLE_DATA":
      return {
        ...state,
        tableData: action.payload,
      };

    case "REC_TABLE":
      return {
        ...state,
        recTable: action.payload,
      };

    case "RULE_NAME_TABLE":
      return {
        ...state,
        ruleName: action.payload,
      };

    case "APMID":
      return {
        ...state,
        apmid: action.payload,
      };
    case "USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };

    default:
      return state;
  }
};

export default tableReducer;
