import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "279d171f-9c20-4c89-ad0b-2e33bf371384",
    authority:
      "https://login.microsoftonline.com/05d75c05-fa1a-42e7-9cf1-eb416c396f2d",
    navigateToLoginRequestUri: true,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

//Addition of scopes here for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["profile", "User.Read"],
};

export const graphConfig = {
  graphIDEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphAPMID: "https://graph.microsoft.com/v1.0/me?$select=employeeId",
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance };
