import React, { useEffect, useState } from "react";
import {
  Button,
  TableV2,
  Typography,
  Input,
  Breadcrumb,
  LoadingIndicator,
  Tooltip,
  Icon,
} from "@anchor/react-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./ApiPage.css";
import ApiModal from "../../components/Modals/ApiModal";
import axios from "axios";
import AuthApiModal from "../../components/Modals/AuthApiModal";

const ApiPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState([]);
  const [Modal1, setModal1] = useState(false);
  const [Modal2, setModal2] = useState(false);
  const tableState = useSelector((state) => state.tableReducer);
  const moduleState = useSelector((state) => state.moduleReducer);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const getRule = async () => {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_PRO_URL}/fetch/rule`, {
          Modulename: moduleState.moduleName,
        })
        .then((res) => {
          let data = [];
          res.data != null
            ? (data = res.data.map((item, index) => ({
                rcount: item.rcount,
                RuleName: item.RuleName,
                configureddate: item.configureddate,
                configuredby: item.configuredby,
              })))
            : console.log(res);
          if (res.data != null)
            dispatch({ type: "RULE_TABLE", payload: res.data });
          setSearchData(data);
          setLoading(false);
        })
        .then(() => {});
    };
    getRule();
  }, []);

  const handleChange = (e) => {
    const data = searchData.filter((item) => {
      return e.target.value.toLowerCase() === ""
        ? item
        : item.RuleName.replace(/\s/g, "")
            .toLowerCase()
            .includes(e.target.value.replace(/\s/g, "").toLowerCase());
    });
    dispatch({ type: "RULE_TABLE", payload: data });
  };

  return (
    <div>
      {Modal1 && <AuthApiModal setModal1={setModal1} setModal2={setModal2} />}
      {Modal2 && <ApiModal setModal1={setModal1} setModal2={setModal2} />}
      <div className="header-div">
        <div className="left-side">
          <Typography className="heading" variant="h4">
            API
          </Typography>
          <Tooltip
            appearance="inverse"
            content="API Page information"
            position="top"
            width="auto"
          >
            <div
              className="info-icon"
              onClick={() => window.open("/api-info", "_blank")}
            >
              <Icon name="info-circle" />
            </div>
          </Tooltip>
          <Breadcrumb
            activeItem="profile"
            className="breadcrumb-story"
            mainObject={{
              home: {
                breadcrumbLocator: "home",
                label: `Module (${moduleState.moduleName})`,
                path: "/",
              },
              profile: {
                breadcrumbLocator: "home.profile",
                label: "API",
                path: "/api",
              },
            }}
            onBreadcrumbClick={(e) => {
              navigate(e.path);
            }}
            separator="/"
          />
        </div>
        <div className="create-button">
          <Button
            appearance="default"
            id="primary"
            justifyItems="center"
            label="Create"
            name="primary"
            onClick={() => {
              setModal1(true);
            }}
            variant="filled"
          />
          {select.length === 0 ? (
            <>
              <div className="delete-icon" disabled>
                <Icon name="trash" />
              </div>
              <div className="edit-icon" disabled>
                <Icon name="pencil" />
              </div>
            </>
          ) : (
            <>
              {select.length > 1 ? (
                <>
                  <div className="delete-icon">
                    <Icon name="trash" />
                  </div>
                  <div className="edit-icon" disabled>
                    <Icon name="pencil" />
                  </div>
                </>
              ) : (
                <>
                  <div className="delete-icon">
                    <Icon name="trash" />
                  </div>
                  <div className="edit-icon">
                    <Icon name="pencil" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="module-table-div">
        <div className="module-table">
          <TableV2
            columnData={[
              {
                accessorKey: "name",
                header: "Name",
                id: "name",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "configureDate",
                alignData: "left",
                header: "Configured Date",
                id: "configureDate",
                meta: {
                  type: "custom",
                },
              },
              {
                accessorKey: "assign",
                alignData: "left",
                header: "Configured By",
                id: "assign",
                meta: {
                  type: "custom",
                },
              },
            ]}
            defaultData={
              [
                {
                  name: "John Doe",
                  configureDate: "2013-01-01",
                  assign: "RRO239",
                },
              ]
              //   tableState.apiTable.map((item, index) => ({
              //     name: (
              //       <div
              //         onClick={() => {
              //           navigate("/api-details");
              //         }}
              //       >
              //         {item.apiName}
              //       </div>
              //     ),
              //     configureDate: item.configureddate,
              //     assign: item.configuredby,
              //   }))
            }
            gridLine="vertical"
            maxHeight={420}
            stickyHeader={true}
            onRowSelection={(e) => {
              setSelect(e);
            }}
            onRowClickCallback={(row, index, e) => {
              navigate("/api-details");
            }}
          />
          {/* {tableState.apiTable.length == 0 && !loading ? (
            <>
              <br />
              <Typography className="heading" variant="h6">
                Insert API to view
              </Typography>
            </>
          ) : (
            <></>
          )}
          {tableState.apiTable.length == 0 && loading && (
            <>
              <br />
              <LoadingIndicator
                appearance="default"
                fit="medium"
                id="testing"
                label="Loading"
                variant="spinner"
              />
            </>
          )} */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ApiPage;
