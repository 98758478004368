const initialState = {
  criteriaValue: [{ key: "", operator: "", value: "" }],
  criterias: [{}],
  criteriaFilter: "",
  ruleName: "",
};
const ruleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CRITERIAS":
      return {
        ...state,
        criterias: action.payload,
      };

    case "CRITERIA_VALUE":
      return {
        ...state,
        criteriaValue: action.payload,
      };

    case "CRITERIA_FILTER":
      return {
        ...state,
        criteriaFilter: action.payload,
      };

    case "RULE_NAME":
      return {
        ...state,
        ruleName: action.payload,
      };

    default:
      return state;
  }
};

export default ruleReducer;
