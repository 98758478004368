import React from "react";
import { Card, Typography } from "@anchor/react-components/dist/lib/components";
import "./RuleInfoPage.css";
//import "./Homepage.css";
import { useNavigate } from "react-router-dom";

function RuleInfoPage() {
  const navigate = useNavigate();
  return(
    <div className="rule-info">
    <Typography variant="h3">
      What is a Rule?
    </Typography>
    <Typography variant="body1">The main aim of the Kitsuni application is to return recommendations based on a bunch of criterias which are used to configure recommendations.</Typography><br/>
    <Typography variant="body1">The Rules page lists out all the rules based on which the recommendations are returned to the modules used by the end user.</Typography>
    <Typography variant="h3">
      How to define/create a Rule?
    </Typography>
  </div>
  );
}

export default RuleInfoPage;
