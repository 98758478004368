import {
  Button,
  Modal,
  Input,
  TextArea,
  ReactSelect,
} from "@anchor/react-components";
import "./RecModal.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { ToastFunction } from "../../ToastFuctions";
const RecModal = ({ setModal2 }) => {
  const recState = useSelector((state) => state.recReducer);
  const ruleState = useSelector((state) => state.ruleReducer);
  const moduleState = useSelector((state) => state.moduleReducer);
  const tableState = useSelector((state) => state.tableReducer);
  const dispatch = useDispatch();
  console.log(recState.recActiveState);
  let today = new Date();
  let date =
    today.getDate() +
    "-" +
    parseInt(today.getMonth() + 1) +
    "-" +
    today.getFullYear();
  let data1 = [];
  let active1 = [];
  const postRecommendation = () => {
    const data = [
      {
        Name: recState.recName,
        Priority: recState.priority,
        Description: recState.recDesc,
        Addedby: tableState.apmid,
        Addeddate: date,
        Isactionactive: true,
        RID: "Loading...",
      },
      ...tableState.recTable,
    ];
    let active = recState.recActiveState;
    console.log(recState.recActiveState);
    active.length == 0 ? active.push(true) : active.unshift(true);
    active1 = active;
    data1 = data;
    axios
      .post(`${process.env.REACT_APP_PRO_URL}/insert/recommendation`, {
        ModuleName: moduleState.moduleName,
        CriteriaName: tableState.ruleName,
        RecName: recState.recName,
        Priority: recState.priority,
        Desc: recState.recDesc,
        AssignId: tableState.apmid,
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: "REC_ID_STATE" });
        dispatch({ type: "REC_NAME", payload: "" });
        dispatch({ type: "REC_DESC", payload: "" });
        dispatch({ type: "REC_PRIORITY", payload: "" });
      })
      .catch(function (error) {
        console.log(error);
      });
    dispatch({ type: "REC_NAME", payload: "" });
    dispatch({ type: "REC_DESC", payload: "" });
    dispatch({ type: "REC_PRIORITY", payload: "" });
  };

  const checkDuplicate = () => {
    let c = 0;
    tableState.recTable.map((item) => {
      console.log("item->", item.Priority, "recstate->", recState.priority);
      if (
        item.Name.replace(/\s/g, "").toLowerCase() ==
        recState.recName.replace(/\s/g, "").toLowerCase()
      )
        ToastFunction("Recommendation name already exists", "error");
      else if (
        item.Description.replace(/\s/g, "").toLowerCase() ==
        recState.recDesc.replace(/\s/g, "").toLowerCase()
      )
        ToastFunction("Recommendation description already exists", "error");
      else if (item.Priority == recState.priority)
        ToastFunction("Priority already exists", "error");
      else c++;
    });
    if (tableState.recTable.length == c) {
      postRecommendation();
      dispatch({ type: "REC_ACTIVE_STATE", payload: active1 });
      dispatch({ type: "REC_TABLE", payload: data1 });
      ToastFunction("Recommendation added !", "success");
    }
  };

  return (
    <Modal
      id="rec-modal-id"
      actions={{
        primaryAction: (
          <Button
            label="Save Changes"
            onClick={() => {
              recState.recName != "" &&
              recState.recDesc != "" &&
              recState.priority != ""
                ? setModal2(false)
                : setModal2(true);
              recState.recName != "" &&
              recState.recDesc != "" &&
              recState.priority != ""
                ? checkDuplicate()
                : ToastFunction(
                    "Please fill all the required fields !",
                    "warning"
                  );
            }}
          />
        ),
        secondaryAction: (
          <Button
            label="Cancel"
            onClick={() => {
              setModal2(false);
              dispatch({ type: "REC_NAME", payload: "" });
              dispatch({ type: "REC_DESC", payload: "" });
              dispatch({ type: "REC_PRIORITY", payload: "" });
            }}
            variant="outlined"
          />
        ),
        tertiaryAction: (
          <Button
            label="Back"
            onClick={() => {
              setModal2(false);
            }}
            variant="outlined"
          />
        ),
      }}
      className="rec-modal"
      open
      dimension="large"
      backdropcloseactiondisabled
      showCloseIcon={false}
      heading="New Recommendation"
      onClose={function noRefCheck() {}}
    >
      <div className="top-input">
        <Input
          className="rec-name-input"
          autocomplete="on"
          errorMessage=""
          fit="medium"
          hint=""
          id="recTextName"
          label="Name"
          maxLength={50}
          onBlur={function noRefCheck() {}}
          onChange={(e) =>
            dispatch({ type: "REC_NAME", payload: e.target.value })
          }
          placeholder="Enter name here...."
          required
          type="text"
          variant="default"
          value={recState.recName}
        />
        <div className="rec-priority-input">
          <ReactSelect
            fit="medium"
            id="rec-priority-input"
            label="Priority"
            name="rec-priority-input"
            required
            options={[
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7",
                value: "7",
              },
              {
                label: "8",
                value: "8",
              },
              {
                label: "9",
                value: "9",
              },
              {
                label: "10",
                value: "10",
              },
            ]}
            menuPortalTarget={document.getElementById("rec-modal-id")}
            placeholder="Select priority of Recommendation"
            onChange={(e) => {
              dispatch({ type: "REC_PRIORITY", payload: e.value });
            }}
          />
        </div>
      </div>
      <div className="bottom-input">
        <TextArea
          required
          fit="medium"
          id="rec-text-area"
          label="Description"
          onChange={(e) =>
            dispatch({ type: "REC_DESC", payload: e.target.value })
          }
          placeholder="Enter the Description..."
          rows={5}
          maxLength={300}
          width={100}
          value={recState.recDesc}
        />
      </div>
    </Modal>
  );
};

export default RecModal;
