import {
  Button,
  TextArea,
  Modal,
  Input,
  ReactSelect,
  TableV2,
  Typography,
  Icon,
  Tooltip,
} from "@anchor/react-components/dist/lib/components";
import "./RuleModal.css";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { ToastFunction } from "../../ToastFuctions";

const RuleModal = ({ setModal1 }) => {
  const ruleState = useSelector((state) => state.ruleReducer);
  const moduleState = useSelector((state) => state.moduleReducer);
  const tableState = useSelector((state) => state.tableReducer);
  const [select, setSelect] = useState([]);
  const [resetSelect, setResetSelect] = useState(false);
  const dispatch = useDispatch();

  let today = new Date();
  let date =
    today.getDate() +
    "-" +
    parseInt(today.getMonth() + 1) +
    "-" +
    today.getFullYear();

  const payloadHandler = () => {
    let Criteria = "";
    let CriteriaKeys = "";
    let Operator = "";

    const obj = {};
    let k = [];
    let o = [];
    ruleState.criteriaValue.forEach((item) => {
      const [key, value] = Object.entries(item)[0];
      const [key2, value2] = Object.entries(item)[2];
      if (obj[value])
        obj[value] = Array.isArray(obj[value])
          ? obj[value].concat(value2)
          : [obj[value], value2];
      else obj[value] = value2;
    });
    Criteria = JSON.stringify(obj);

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        for (const obj of ruleState.criteriaValue) {
          if (key == obj.key) {
            k.push(key);
            o.push(obj.operator);
          }
        }
      }
    }

    CriteriaKeys = JSON.stringify(k);
    Operator = JSON.stringify(o);

    let data = {
      ModuleName: moduleState.moduleName,
      CriteriaName: ruleState.ruleName,
      Criteria: JSON.stringify(ruleState.criteriaValue),
      CriteriaKeys: CriteriaKeys,
      Filter: ruleState.criteriaFilter,
      Operator: Operator,
      AssignId: tableState.apmid,
    };
    return data;
  };

  let data1 = [];

  const ruleHandler = (payload) => {
    const data = [
      {
        RuleName: ruleState.ruleName,
        rcount: 0,
        configureddate: date,
        configuredby: tableState.apmid,
      },
      ...tableState.ruleTable,
    ];
    data1 = data;
    axios
      .post(`${process.env.REACT_APP_PRO_URL}/insert/rule`, payload)
      .then((res) => {
        dispatch({
          type: "CRITERIA_VALUE",
          payload: [{ key: "", operator: "", value: "" }],
        });
        dispatch({ type: "CRITERIAS", payload: [{}] });
        dispatch({ type: "CRITERIA_FILTER", payload: "" });
        dispatch({ type: "RULE_NAME", payload: "" });
      });
  };

  const addCriteria = () => {
    const abc = [
      ...ruleState.criteriaValue,
      { key: "", operator: "", value: "" },
    ];
    dispatch({ type: "CRITERIA_VALUE", payload: abc });
    const newValue = [...ruleState.criterias, {}];
    dispatch({ type: "CRITERIAS", payload: newValue });
  };

  const handleKeyChange = (value, index) => {
    const inputData = [...ruleState.criteriaValue];
    inputData[index].key = value.target.value;
    dispatch({ type: "CRITERIA_VALUE", payload: inputData });
  };

  const handleValueChange = (value, index) => {
    const inputData = [...ruleState.criteriaValue];
    inputData[index].value = value.target.value;
    dispatch({ type: "CRITERIA_VALUE", payload: inputData });
  };
  const handleOperatorChange = (value, index) => {
    let inputData = [...ruleState.criteriaValue];
    inputData[index].operator = value.value;
    dispatch({ type: "CRITERIA_VALUE", payload: inputData });
  };

  const checkValue = { key: "", operator: "", value: "" };

  const checkDuplicateRuleName = () => {
    let c = 0;
    tableState.ruleTable.map((item) => {
      item.RuleName.replace(/\s/g, "").toLowerCase() ==
      ruleState.ruleName.replace(/\s/g, "").toLowerCase()
        ? ToastFunction("Rule name already exists", "error")
        : c++;
    });
    if (tableState.ruleTable.length == c) {
      ruleHandler(payloadHandler());
      dispatch({ type: "RULE_TABLE", payload: data1 });
      ToastFunction("Rule added !", "success");
    }
  };

  const checkFilterNumeric = () => {
    let j = 0;
    for (j = 0; j < ruleState.criteriaValue.length; j++) {
      if (ruleState.criteriaFilter.indexOf(`${j + 1}`) === -1) {
        return false;
      }
    }
    for (let i in ruleState.criteriaFilter) {
      if (/[0-9]/.test(ruleState.criteriaFilter[i])) {
        if (!ruleState.criteriaValue[parseInt(ruleState.criteriaFilter[i]) - 1])
          return false;
      }
    }
    return true;
  };

  const checkFilter = () => {
    let result;
    let result1;
    const regExp = /[a-zA-Z]/g;
    regExp.test(ruleState.criteriaFilter) ? (result = false) : (result = true);
    checkFilterNumeric() && result == true
      ? (result1 = true)
      : (result = false);
    return result1;
  };

  const checkCriteria = () => {
    let i = 0;
    for (i = 0; i < ruleState.criteriaValue.length; i++) {
      ruleState.criteriaValue[i].key = ruleState.criteriaValue[i].key.trim();
      ruleState.criteriaValue[i].value =
        ruleState.criteriaValue[i].value.trim();
      if (
        ruleState.criteriaValue[i].key == "" ||
        ruleState.criteriaValue[i].operator == "" ||
        ruleState.criteriaValue[i].value == ""
      ) {
        return false;
      }
    }
    return true;
  };
  const deleteCriteria = () => {
    let arr = ruleState.criterias;
    let arr1 = ruleState.criteriaValue;
    const remove = select.map((item) => {
      return item.ID - 1;
    });
    console.log(remove);
    for (var i = remove.length - 1; i >= 0; i--) {
      arr.splice(remove[i], 1);
      arr1.splice(remove[i], 1);
    }
    dispatch({ type: "CRITERIAS", payload: arr });
    dispatch({ type: "CRITERIA_VALUE", payload: arr1 });
    setResetSelect(true);
  };

  return (
    <>
      <Modal
        id="rule-modal"
        actions={{
          primaryAction: (
            <Button
              label="Save Changes"
              onClick={() => {
                !ruleState.criteriaValue.includes(checkValue) &&
                ruleState.criteriaFilter != "" &&
                ruleState.ruleName != "" &&
                checkCriteria() &&
                checkFilter() &&
                checkFilterNumeric()
                  ? setModal1(false)
                  : setModal1(true);

                !ruleState.criteriaValue.includes(checkValue) &&
                ruleState.criteriaFilter != "" &&
                ruleState.ruleName != "" &&
                checkCriteria()
                  ? checkFilter()
                    ? checkDuplicateRuleName()
                    : ToastFunction("Filter logic is not correct", "error")
                  : ToastFunction(
                      "Please fill all the required fields !",
                      "warning"
                    );
              }}
            />
          ),
          secondaryAction: (
            <Button
              label="Cancel"
              onClick={() => {
                setModal1(false);
                dispatch({
                  type: "CRITERIA_VALUE",
                  payload: [{ key: "", operator: "", value: "" }],
                });
                dispatch({ type: "CRITERIAS", payload: [{}] });
                dispatch({ type: "CRITERIA_FILTER", payload: "" });
                dispatch({ type: "RULE_NAME", payload: "" });
              }}
              variant="outlined"
            />
          ),
          tertiaryAction: (
            <Button
              label="Back"
              onClick={() => {
                setModal1(false);
              }}
              variant="outlined"
            />
          ),
        }}
        open
        dimension="large"
        backdropcloseactiondisabled
        heading="New Rule"
        showCloseIcon={false}
      >
        <div className="modal-body">
          <div className="left-input">
            <Input
              className="rule-name-input"
              autocomplete="on"
              errorMessage=""
              fit="large"
              id="ruleTextName"
              label="Rule Name *"
              maxLength={50}
              onChange={(e) =>
                dispatch({ type: "RULE_NAME", payload: e.target.value })
              }
              placeholder="Enter Rule Name"
              type="text"
              value={ruleState.ruleName}
              variant="default"
            />
            <Tooltip
              className="tooltip-rule"
              appearance="inverse"
              content="Filter logic governs how and when filters apply to your list view. 
              Use AND and OR operators to fine-tune your results."
              position="top"
              width="10"
            >
              <Icon name="info-circle" />
            </Tooltip>
            <TextArea
              fit="=large"
              hint=""
              className="rule-filter-input"
              id="text-area"
              label="Filter Logic *"
              onChange={(e) =>
                dispatch({ type: "CRITERIA_FILTER", payload: e.target.value })
              }
              placeholder="Enter the Description..."
              rows={10}
              value={ruleState.criteriaFilter}
            />
          </div>
          <div className="table-div">
            <Typography className="table-lable" variant="body">
              Enter Criteria *
            </Typography>
            <TableV2
              className="table-main"
              id="operator-table"
              label="criteria"
              columnData={[
                {
                  accessorKey: "ID",
                  header: "",
                  id: "ID",
                  alignData: "center",
                  meta: {
                    type: "custom",
                  },
                },
                {
                  accessorKey: "key",
                  id: "key",
                  alignData: "center",
                  header: "Key",
                  meta: {
                    type: "custom",
                  },
                },
                {
                  accessorKey: "operator",
                  id: "operator",
                  alignData: "left",
                  header: "Operator",
                  meta: {
                    type: "custom",
                  },
                },
                {
                  accessorKey: "value",
                  id: "value",
                  alignData: "left",
                  header: "Value",
                  meta: {
                    type: "custom",
                  },
                },
              ]}
              defaultData={ruleState.criterias.map((data, index) => {
                return {
                  ID: index + 1,
                  key: (
                    <Input
                      className="rec-name-input"
                      autocomplete="on"
                      errorMessage=""
                      fit="medium"
                      hint=""
                      id="recTextName"
                      maxLength={50}
                      onChange={(e) => handleKeyChange(e, index)}
                      placeholder="Enter Key"
                      required
                      tooltipDescription="Enter your name here"
                      type="text"
                      variant="default"
                      value={ruleState.criteriaValue[index].key}
                    />
                  ),
                  operator:
                    ruleState.criteriaValue[index].operator != "" ? (
                      <ReactSelect
                        fit="medium"
                        id="selectpicker"
                        isSearchable
                        placeholder="Select operator"
                        menuPortalTarget={document.getElementById("rule-modal")}
                        name="picknumber"
                        options={[
                          { label: "Equals to", value: "==" },
                          { label: "Not Equals to", value: "!=" },
                        ]}
                        onChange={(e) => handleOperatorChange(e, index)}
                        value={[
                          {
                            label:
                              ruleState.criteriaValue[index].operator === "=="
                                ? "Equals to"
                                : "Not Equals to",
                            value: ruleState.criteriaValue[index].value,
                          },
                        ]}
                      />
                    ) : (
                      <ReactSelect
                        fit="medium"
                        id="selectpicker"
                        isSearchable
                        placeholder="Select operator"
                        menuPortalTarget={document.getElementById("rule-modal")}
                        name="picknumber"
                        options={[
                          { label: "Equals to", value: "==" },
                          { label: "Not Equals to", value: "!=" },
                        ]}
                        onChange={(e) => handleOperatorChange(e, index)}
                      />
                    ),
                  value: (
                    <Input
                      className="rec-name-input"
                      autocomplete="on"
                      fit="medium"
                      id="recTextName"
                      maxLength={50}
                      onChange={(e) => handleValueChange(e, index)}
                      placeholder="Enter Value"
                      required
                      tooltipDescription="Enter your name here"
                      type="text"
                      variant="default"
                      value={ruleState.criteriaValue[index].value}
                    />
                  ),
                };
              })}
              gridLine="horizontal"
              stickyHeader="true"
              maxWidth="1000"
              maxHeight="300"
              onRowSelection={(e) => {
                setSelect(e);
                setResetSelect(false);
              }}
              resetRowSelection={resetSelect}
              rowSelector={[
                {
                  id: "customSelect",
                },
              ]}
            />
            <div className="button-groups">
              <Button
                className="criteria-button"
                label="Add Criteria"
                onClick={() => {
                  addCriteria();
                }}
              />
              {select.length == 0 ? (
                <div className="delete-icon" disabled>
                  <Icon name="trash" />
                </div>
              ) : (
                <div
                  className="delete-icon"
                  onClick={() => {
                    deleteCriteria();
                  }}
                >
                  <Icon name="trash" />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RuleModal;
