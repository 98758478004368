import { ToastContainer, toast } from "react-toastify";
export const ToastFunction = (message, type) => {
  const params = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  switch (type) {
    case "success":
      toast.success(message, params);
      break;
    case "error":
      toast.error(message, params);
      break;
    case "warning":
      toast.warning(message, params);
      break;
  }
};
