import React, { useEffect, useState } from "react";
import Menubar from "@anchor/anchor-widgets/dist/lib/components/MenuBar/Menubar";
import "./NavbarNew.css";
import axios from "axios";
import { graphConfig } from "../Auth/msalAuthConfig";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Typography, Icon } from "@anchor/react-components/dist/lib/components";
import Hamburger from "../Drawer/Hamburger";

const NavbarNew = () => {
  const tableState = useSelector((state) => state.tableReducer);
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //Function to retreive APMID
  const apmidRetrive = () => {
    let apmidToken = "";
    let rbacToken = "";
    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key))
        if (
          "secret" in JSON.parse(localStorage.getItem(key)) &&
          "tokenType" in JSON.parse(localStorage.getItem(key))
        )
          apmidToken = JSON.parse(localStorage.getItem(key)).secret;
        else if ("credentialType" in JSON.parse(localStorage.getItem(key)))
          rbacToken = JSON.parse(localStorage.getItem(key)).secret;
    }
    console.log(rbacToken);
    const rbac_headers = { Authorization: `Bearer ${apmidToken}` };
    const headers = { Authorization: `Bearer ${apmidToken}` };
    axios
      .get(graphConfig.graphIDEndpoint, {
        headers,
      })
      .then((res) => {
        dispatch({
          type: "USER_INFO",
          payload: res.data,
        });
      });
    axios
      .get(graphConfig.graphAPMID, {
        headers,
      })
      .then((res) => {
        dispatch({ type: "APMID", payload: res.data.employeeId });
      });
  };
  useEffect(() => {
    try {
      apmidRetrive();
    }catch(err){
      console.log("Error: ", err);
    }
  }, []);

  //Function to handle Logout Functionality
  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <>
      <div className="navbar">
        <div className="left-side">
          <img className="logo" src="/maersk.png" />
          <div className="line" />
          <div className="logo-name">
            <Typography variant="h6">Kitsuni</Typography>
          </div>
        </div>
        <div className="right-side-navbar">
          <div
            className="dashboard"
            onClick={() => {
              window.open(
                "https://app.powerbi.com/links/cw5p3gwfKD?ctid=05d75c05-fa1a-42e7-9cf1-eb416c396f2d&pbi_source=linkShare",
                "_blank"
              );
            }}
          >
            <Icon name="chart-bars-vertical" />
          </div>
          {!isMenuOpen && (
            <div
              className="hamburger"
              onClick={() => {
                setIsMenuOpen(true);
                console.log("clicked");
              }}
            >
              <Icon name="bars-horizontal" />
            </div>
          )}
        </div>
        <Hamburger
          setIsMenuOpen={setIsMenuOpen}
          isMenuOpen={isMenuOpen}
          handleLogout={handleLogout}
        />
      </div>
      <div className="separator" />
    </>
  );
};

export default NavbarNew;
