import React from "react";
import { Card, Typography } from "@anchor/react-components/dist/lib/components";
import "./RecInfoPage.css";
//import "./Homepage.css";
import { useNavigate } from "react-router-dom";

function RecInfoPage() {
  const navigate = useNavigate();
  return (
    <div className="rec-info">
    <Typography variant="h3">
      What is a Recommendation?
    </Typography>
    <Typography variant="body1">This page defines all the Recommendations which are to be returned to the respective modules by the Kitsuni Application</Typography>

    <Typography variant="h3">
      How to define/create a Recommendation?
    </Typography>
  </div>
  );
}

export default RecInfoPage;
