import ruleReducer from "./ruleReducer";
import moduleReducer from "./moduleReducer";
import recReducer from "./recReducer";
import tableReducer from "./tableReducer";
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import apiReducer from "./apiReducer";

const rootReducer = combineReducers({
  ruleReducer,
  moduleReducer,
  recReducer,
  tableReducer,
  authReducer,
  apiReducer,
});

export default rootReducer;
