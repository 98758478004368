const initialState = {
  moduleName: "",
};
const moduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MODULE_NAME":
      return {
        moduleName: action.payload,
      };
    default:
      return state;
  }
};

export default moduleReducer;
