import { Button, Modal, Input } from "@anchor/react-components";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ModuleModal.css";
import { ToastFunction } from "../../ToastFuctions";

const EditModuleModal = ({ setEditModal, select, setResetSelect }) => {
  const dispatch = useDispatch();
  const [newModule, setNewModule] = useState(select[0].name.props.children);
  const tableState = useSelector((state) => state.tableReducer);
  const [Table, setTable] = useState([]);

  let today = new Date();
  let date =
    today.getDate() +
    "-" +
    parseInt(today.getMonth() + 1) +
    "-" +
    today.getFullYear();

  useEffect(() => {
    dispatch({ type: "REC_NAME", payload: select[0].name });
    dispatch({ type: "REC_DESC", payload: select[0].desc });
    dispatch({ type: "REC_PRIORITY", payload: select[0].priority });
    let Table = tableState.moduleTable;
    Table = Table.filter(
      (obj) => obj.modulename !== select[0].name.props.children
    );
    setTable(Table);
  }, []);

  const ModuleSaveHandler = () => {
    axios
      .post(`${process.env.REACT_APP_PRO_URL}/update/module`, {
        ModuleName: select[0].name.props.children,
        AssignId: tableState.apmid,
        NewModuleName: newModule,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const checkDuplicate = () => {
    let c = 0;
    Table.map((item) => {
      item.modulename.replace(/\s/g, "").toLowerCase() ==
      newModule.replace(/\s/g, "").toLowerCase()
        ? ToastFunction("Module already exists !", "error")
        : c++;
    });
    if (Table.length == c) {
      ModuleSaveHandler();
      setResetSelect(true);
      updateRow();
      ToastFunction("Module updated !", "success");
    }
  };

  const updateRow = () => {
    let abcClone = tableState.moduleTable;
    let i;
    abcClone.map((obj, index) => {
      if (obj.modulename === select[0].name.props.children) {
        i = index;
      }
    });
    abcClone.splice(i, 1, {
      modulename: newModule,
      rcount: select[0].rules,
      addedby: tableState.apmid,
      addeddate: select[0].addedDate,
    });
    dispatch({ type: "MODULE_TABLE", payload: abcClone });
  };

  return (
    <Modal
      actions={{
        primaryAction: (
          <Button
            label="Save"
            onClick={() => {
              newModule != ""
                ? checkDuplicate()
                : ToastFunction("Empty Module cannot be added !", "warning");
              newModule ? setEditModal(false) : setEditModal(true);
            }}
          />
        ),
        tertiaryAction: (
          <Button
            label="Cancel"
            onClick={() => {
              setResetSelect(true);
              setEditModal(false);
            }}
            variant="outlined"
          />
        ),
      }}
      className="module-modal"
      heading="New Module"
      dimension="medium"
      open
      onClose={function noRefCheck() {}}
      showCloseIcon={false}
    >
      <div className="top-input">
        <Input
          className="moduleInput"
          errorMessage=""
          autocomplete="on"
          fit="medium"
          hint=""
          id="moduleName"
          label="Module Name"
          maxLength={50}
          onBlur={function noRefCheck() {}}
          onChange={(e) => {
            setNewModule(e.target.value);
          }}
          placeholder="Enter New Module Name"
          required
          type="text"
          variant="default"
          value={newModule}
        />
      </div>
    </Modal>
  );
};
export default EditModuleModal;
