import { Route, Routes } from "react-router-dom";

//resuable UI components
// import NavBar from "./components/Navbar/Navbar";
// MSAL imports
// import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./components/Auth/msalAuthConfig";

//UI components
import ModulePage from "./pages/ModulePage/ModulePage";
import RulePage from "./pages/RulePage/RulePage";
import RecPage from "./pages/RecommendationPage/RecPage";
import ModuleInfoPage from "./pages/ModuleInfoPage/ModuleInfoPage";
import RuleInfoPage from "./pages/RuleInfoPage/RuleInfoPage";
import RecInfoPage from "./pages/RecInfoPage/RecInfoPage";
import NavbarNew from "./components/Navbar new/NavbarNew";
import ApiPage from "./pages/ApiPage/ApiPage";
import APIDetailsPage from "./pages/APIDetails/APIDetails";
import { Typography } from "@anchor/react-components/dist/lib/components";

const App = () => {
  function ErrorComponent({ error }) {
    return <p>An Error Occurred: {error}</p>;
  }

  function LoadingComponent() {
    return (
      <Typography
        style={{
          marginLeft: "35%",
          marginTop: "20px",
        }}
        variant="h4"
        fontStyle="bold"
      >
        Authentication in progress...
      </Typography>
    );
  }
  return (
    <div className="App">
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <NavbarNew />

        <Routes>
          <Route path="/" element={<ModulePage />} />
          <Route exact path="/api" element={<ApiPage />} />
          <Route exact path="/api-details" element={<APIDetailsPage />} />
          <Route exact path="/module" element={<ModulePage />} />
          <Route exact path="/rules" element={<RulePage />} />
          <Route exact path="/recommendation" element={<RecPage />} />
          <Route exact path="/module-info" element={<ModuleInfoPage />} />
          <Route exact path="/rule-info" element={<RuleInfoPage />} />
          <Route exact path="/rec-info" element={<RecInfoPage />} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </MsalAuthenticationTemplate>
    </div>
  );
};

export default App;
