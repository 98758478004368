const initialState = {
  priority: "",
  recDesc: "",
  recName: "",
  recIdState: false,
  recActiveState: [],
};
const recReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REC_PRIORITY":
      return {
        ...state,
        priority: action.payload,
      };

    case "REC_NAME":
      return {
        ...state,
        recName: action.payload,
      };

    case "REC_DESC":
      return {
        ...state,
        recDesc: action.payload,
      };

    case "REC_ID_STATE":
      return {
        ...state,
        recIdState: !state.recIdState,
      };

    case "REC_ACTIVE_STATE":
      return {
        ...state,
        recActiveState: action.payload,
      };

    default:
      return state;
  }
};

export default recReducer;
