const initialState = {
  authHeaderValue: [{ key: "", operator: "", value: "" }],
  authHeader: [{}],
  authEndpoint: "",
  authApiName: "",
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HEADER":
      return {
        ...state,
        headerValue: action.payload,
      };

    case "HEADER_VALUE":
      return {
        ...state,
        header: action.payload,
      };

    case "AUTH_ENDPOINT":
      return {
        ...state,
        authEndpoint: action.payload,
      };

    case "AUTH_API_NAME":
      return {
        ...state,
        authApiName: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
